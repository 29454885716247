

/*.app-label{*/
/*    width: 50%;*/
/*    overflow: auto;*/
/*    margin: auto;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    z-index: 2;*/
/*    background: white;*/
/*    pointer-events: none;*/
/*}*/

.map-container{
    height: calc(100vh - 85px);
    max-height: calc(100vh - 76px);
    /*display: flex;*/
    /*justifyContent: "space-between";*/
    width: 100%;
    margin-top: 62px;
}

.clicked-coord-label{
    position: absolute;
    right: 0;
    bottom: 0;
    background: white;
    border-radius: 5px;
}

.clicked-coord-label p {
    margin: 10px;
}

.inv {
    filter: invert(100%);
}